// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bx_k4";
export var caseStudyBackground__lineColor = "bx_kW";
export var caseStudyHead = "bx_k6";
export var caseStudyHead__imageWrapper = "bx_kT";
export var caseStudyProjectsSection = "bx_k5";
export var caseStudyQuote__bgRing = "bx_k1";
export var caseStudyQuote__bottomVideo = "bx_lZ";
export var caseStudyVideoReview = "bx_l2";
export var caseStudyVideoReview__bgRing = "bx_l3";
export var caseStudyVideo__ring = "bx_k8";
export var caseStudy__bgDark = "bx_kS";
export var caseStudy__bgLight = "bx_kR";
export var caseStudy__bgLightReverse = "bx_l1";